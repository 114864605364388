import React from "react";
import AdminHeader from "../components/Layout/AdminHeader";
import AdminSideBar from "../components/Admin/Layout/AdminSideBar";
import AllCategories from "../components/Admin/AllCategories";

const AdminDashboardCategory = () => {
  return (
    <div className="w-full overflow-x-auto relative">
      <AdminHeader />
      <div className="w-full flex">
        <div className="flex items-start justify-between w-full">
          <div className="w-[50px] h-screen 800px:w-[300px]">
            <AdminSideBar active={9} />
          </div>
          <AllCategories />
        </div>
      </div>
    </div>
  );
};

export default AdminDashboardCategory;
