import { Avatar, Badge, WindmillContext } from "@windmill/react-ui";
import Cookies from "js-cookie";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
// import { AiOutlineGift } from "react-icons/ai";
// import { MdOutlineLocalOffer } from "react-icons/md";
// import { FiPackage, FiShoppingBag } from "react-icons/fi";

import {
  IoClose,
  IoGridOutline,
  IoLogOutOutline,
  // IoMenu,
  // IoMoonSharp,
  IoNotificationsSharp,
  IoSettingsOutline,
  // IoSunny,
} from "react-icons/io5";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { BiMessageSquareDetail } from "react-icons/bi";

import cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import de from "../../../assets/img/de.svg";
import en from "../../../assets/img/us.svg";
import { AdminContext } from "../../context/AdminContext";
import { SidebarContext } from "../../context/SidebarContext";
import { REACT_APP_ADMIN_DOMAIN } from "../../../server";

const DashboardHeader = () => {
  const { seller } = useSelector((state) => state.seller);

  // const reduxDisPatch = useDispatch();
  const { state, dispatch } = useContext(AdminContext);
  // const { adminInfo } = state;
  // const { mode, toggleMode } = useContext(WindmillContext);
  const { handleLanguageChange } = useContext(SidebarContext);

  const [profileOpen, setProfileOpen] = useState(false);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const pRef = useRef();
  const nRef = useRef();

  const currentLanguageCode = cookies.get("i18next") || "en";
  const { t } = useTranslation();

  const handleLogOut = () => {
    //
    dispatch({ type: "USER_LOGOUT" });
    Cookies.remove("seller_token");
    window.location.replace(`${REACT_APP_ADMIN_DOMAIN}/shop-login`);
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (!pRef?.current?.contains(e.target)) {
        setProfileOpen(false);
      }
      if (!nRef?.current?.contains(e.target)) {
        setNotificationOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [pRef, nRef]);

  const handleNotificationOpen = () => {
    setNotificationOpen(!notificationOpen);
    setProfileOpen(false);
  };
  const handleProfileOpen = () => {
    setProfileOpen(!profileOpen);
    setNotificationOpen(false);
  };

  return (
    <div className="w-full h-[80px] bg-white shadow sticky top-0 left-0 z-30 flex items-center justify-between px-4 py-2">
      <div>
        <Link to="/dashboard">
          <img
            src="https://res.cloudinary.com/dswna4lpk/image/upload/v1697226169/Bag_alt_pgykgr.png"
            alt=""
            className="h-[45px] w-[50px]"
          />
        </Link>
      </div>

      <div className="flex items-center">
        {/* <div className="flex gap-[10px] items-center mr-4">
          <Link to="/dashboard-coupouns" className="800px:block hidden hover:bg-[#00000010] p-2 rounded-full">
            <AiOutlineGift
              color="#555"
              size={30}
              className="cursor-pointer"
            />
          </Link>

          <Link to="/dashboard-events" className="800px:block hidden hover:bg-[#00000010] p-2 rounded-full">
            <MdOutlineLocalOffer
              color="#555"
              size={30}
              className="cursor-pointer"
            />
          </Link>

          <Link to="/dashboard-products" className="800px:block hidden hover:bg-[#00000010] p-2 rounded-full">
            <FiShoppingBag
              color="#555"
              size={30}
              className="cursor-pointer"
            />
          </Link>

          <Link to="/dashboard-orders" className="800px:block hidden hover:bg-[#00000010] p-2 rounded-full">
            <FiPackage 
              color="#555" 
              size={30} 
              className="cursor-pointer" 
            />
          </Link>

          <Link to="/dashboard-messages" className="800px:block hidden hover:bg-[#00000010] p-2 rounded-full">
            <BiMessageSquareDetail
              color="#555"
              size={30}
              className="cursor-pointer"
            />
          </Link>

          <Link to={`/shop/${seller._id}`}>
            <img
              src={`${seller.avatar}`}
              alt=""
              className="w-[40px] h-[40px] rounded-full object-cover"
            />
          </Link>
        </div> */}

        <ul className="flex justify-end items-center flex-shrink-0 space-x-6">
          <li className="changeLanguage">
            <div className="dropdown">
              <button className="dropbtn focus:outline-none">
                {currentLanguageCode === "de" ? (
                  <img src={de} width={16} alt="lang" className="mx-2" />
                ) : (
                  <img src={en} className="mx-2" alt="lang" width={16} />
                )}
                {currentLanguageCode === "de" ? "GERMAN" : "ENGLISH"}
              </button>

              <div className="dropdown-content">
                <div
                  onClick={() => handleLanguageChange("en")}
                  className="focus:outline-none cursor-pointer"
                >
                  <img src={en} width={16} alt="lang" /> English{" "}
                </div>
                <div
                  onClick={() => handleLanguageChange("de")}
                  className="focus:outline-none cursor-pointer"
                >
                  <img src={de} width={16} alt="lang" /> German
                </div>
              </div>
            </div>
          </li>

          {/* <!-- Notifications menu --> */}
          <li className="relative inline-block text-left" ref={nRef}>
            <button
              className="relative align-middle rounded-md focus:outline-none"
              onClick={handleNotificationOpen}
            >
              <IoNotificationsSharp className="w-5 h-5" aria-hidden="true" />
              <span className="absolute z-10 top-0 right-0 inline-flex items-center justify-center p-1 h-5 w-5 text-xs font-medium leading-none text-red-100 transform -translate-x-1/2 -translate-y-1/2 bg-red-500 rounded-full">
                6
              </span>
            </button>

            {notificationOpen && (
              <div className="origin-top-right absolute right-0 mt-2 rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="notification-box">
                  <Scrollbars>
                    <ul className="block text-sm border-t border-gray-100 dark:border-gray-700 rounded-md">
                      <li className="flex justify-between items-center font-serif font-normal text-sm py-3 border-b border-gray-100 dark:border-gray-700 px-3 transition-colors duration-150 hover:bg-gray-50 hover:text-gray-800 dark:text-gray-400 dark:hover:bg-gray-900 dark:hover:text-gray-100 cursor-pointer">
                        <div className="flex items-center">
                          <Avatar
                            className="p-1 mr-2 md:block bg-gray-50 border border-gray-200"
                            src="https://i.postimg.cc/tCsSNSxS/Yellow-Sweet-Corn-Bag-each.jpg"
                            alt="image"
                          />

                          <div className="notification-content">
                            <h6 className="font-medium text-gray-500">
                              Yellow Sweet Corn Stock out, please check!
                            </h6>

                            <p className="flex items-center text-xs text-gray-400">
                              <Badge type="danger">Stock Out</Badge>

                              <span className="ml-2">
                                Dec 12 2021 - 12:40PM
                              </span>
                            </p>
                          </div>
                        </div>

                        <span className="px-2">
                          <IoClose />
                        </span>
                      </li>

                      <li className="flex justify-between items-center font-serif font-normal text-sm py-3 border-b border-gray-100 dark:border-gray-700 px-3 transition-colors duration-150 hover:bg-gray-50 hover:text-gray-800 dark:text-gray-400 dark:hover:bg-gray-900 dark:hover:text-gray-100 cursor-pointer">
                        <div className="flex items-center">
                          <Avatar
                            className="mr-2 md:block bg-gray-50 border border-gray-200"
                            src="https://i.ibb.co/ZTWbx5z/team-1.jpg"
                            alt="image"
                          />

                          <div className="notification-content">
                            <h6 className="font-medium text-gray-500">
                              Sam L. Placed{" "}
                              <span className="font-bold">$300</span> USD order!
                            </h6>

                            <p className="flex items-center text-xs text-gray-400">
                              <Badge type="success">New Order</Badge>

                              <span className="ml-2">Nov 30 2021 - 2:40PM</span>
                            </p>
                          </div>
                        </div>

                        <span className="px-2">
                          <IoClose />
                        </span>
                      </li>

                      <li className="flex justify-between items-center font-serif font-normal text-sm py-3 border-b border-gray-100 dark:border-gray-700 px-3 transition-colors duration-150 hover:bg-gray-50 hover:text-gray-800 dark:text-gray-400 dark:hover:bg-gray-900 dark:hover:text-gray-100 cursor-pointer">
                        <div className="flex items-center">
                          <Avatar
                            className="p-1 mr-2 md:block bg-gray-50 border border-gray-200"
                            src="https://i.postimg.cc/5y7rNDFv/Radicchio-12ct.jpg"
                            alt="image"
                          />

                          <div className="notification-content">
                            <h6 className="font-medium text-gray-500">
                              Radicchio Stock out, please check!
                            </h6>

                            <p className="flex items-center text-xs text-gray-400">
                              <Badge type="danger">Stock Out</Badge>

                              <span className="ml-2">
                                Dec 15 2021 - 12:40PM
                              </span>
                            </p>
                          </div>
                        </div>

                        <span className="px-2">
                          <IoClose />
                        </span>
                      </li>

                      <li className="flex justify-between items-center font-serif font-normal text-sm py-3 border-b border-gray-100 dark:border-gray-700 px-3 transition-colors duration-150 hover:bg-gray-50 hover:text-gray-800 dark:text-gray-400 dark:hover:bg-gray-900 dark:hover:text-gray-100 cursor-pointer">
                        <div className="flex items-center">
                          <Avatar
                            className="mr-2 md:block bg-gray-50 border border-gray-200"
                            src="https://i.postimg.cc/SNmQX9Yx/Organic-Baby-Carrot-1oz.jpg"
                            alt="image"
                          />

                          <div className="notification-content">
                            <h6 className="font-medium text-gray-500">
                              Organic Baby Carrot Stock out, please check!
                            </h6>

                            <p className="flex items-center text-xs text-gray-400">
                              <Badge type="danger">Stock Out</Badge>

                              <span className="ml-2">
                                Dec 20 2021 - 12:40PM
                              </span>
                            </p>
                          </div>
                        </div>

                        <span className="px-2">
                          <IoClose />
                        </span>
                      </li>

                      <li className="flex justify-between items-center font-serif font-normal text-sm py-3 border-b border-gray-100 dark:border-gray-700 px-3 transition-colors duration-150 hover:bg-gray-50 hover:text-gray-800 dark:text-gray-400 dark:hover:bg-gray-900 dark:hover:text-gray-100 cursor-pointer">
                        <div className="flex items-center">
                          <Avatar
                            className="mr-2 md:block bg-gray-50 border border-gray-200"
                            src="https://i.postimg.cc/nM8QfhcP/Orange-20ct.jpg"
                            alt="image"
                          />

                          <div className="notification-content">
                            <h6 className="font-medium text-gray-500">
                              Orange Stock out, please check!
                            </h6>

                            <p className="flex items-center text-xs text-gray-400">
                              <Badge type="danger">Stock Out</Badge>

                              <span className="ml-2">
                                Dec 25 2021 - 12:40PM
                              </span>
                            </p>
                          </div>
                        </div>

                        <span className="px-2">
                          <IoClose />
                        </span>
                      </li>

                      <li className="flex justify-between items-center font-serif font-normal text-sm py-3 border-b border-gray-100 dark:border-gray-700 px-3 transition-colors duration-150 hover:bg-gray-50 hover:text-gray-800 dark:text-gray-400 dark:hover:bg-gray-900 dark:hover:text-gray-100 cursor-pointer">
                        <div className="flex items-center">
                          <Avatar
                            className="mr-2 md:block bg-gray-50 border border-gray-200"
                            src="https://i.ibb.co/GWVWYNn/team-7.jpg"
                            alt="Josh"
                          />

                          <div className="notification-content">
                            <h6 className="font-medium text-gray-500">
                              John Doe Placed{" "}
                              <span className="font-bold">$513</span> USD order!
                            </h6>

                            <p className="flex items-center text-xs text-gray-400">
                              <Badge type="success">New Order</Badge>

                              <span className="ml-2">
                                Dec 18 2021 - 12:40PM
                              </span>
                            </p>
                          </div>
                        </div>

                        <span className="px-2">
                          <IoClose />
                        </span>
                      </li>
                    </ul>
                  </Scrollbars>
                </div>
              </div>
            )}
          </li>

          {/* <!-- Profile menu --> */}
          <li className="relative inline-block text-left" ref={pRef}>
            <button
              className="rounded-full dark:bg-gray-500 bg-green-500 text-white h-8 w-8 font-medium mx-auto focus:outline-none"
              onClick={handleProfileOpen}
            >
              {seller?.avatar ? (
                <Avatar
                  className="align-middle"
                  src={`${seller.avatar}`}
                  aria-hidden="true"
                />
              ) : (
                <span>{seller.email[0].toUpperCase()}</span>
              )}
            </button>

            {profileOpen && (
              <ul className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5 focus:outline-none">
                <li className="justify-between font-serif font-medium py-2 pl-4 transition-colors duration-150 hover:bg-gray-100 text-gray-500 hover:text-green-500 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-gray-200">
                  <Link to="/dashboard">
                    <span className="flex items-center text-sm">
                      <IoGridOutline
                        className="w-4 h-4 mr-3"
                        aria-hidden="true"
                      />
                      <span>{t("Dashboard")}</span>
                    </span>
                  </Link>
                </li>

                <li className="justify-between font-serif font-medium py-2 pl-4 transition-colors duration-150 hover:bg-gray-100 text-gray-500 hover:text-green-500 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-gray-200">
                  <Link to="/edit-profile">
                    <span className="flex items-center text-sm">
                      <IoSettingsOutline
                        className="w-4 h-4 mr-3"
                        aria-hidden="true"
                      />
                      <span>{t("EditProfile")}</span>
                    </span>
                  </Link>
                </li>

                <li
                  onClick={handleLogOut}
                  className="cursor-pointer justify-between font-serif font-medium py-2 pl-4 transition-colors duration-150 hover:bg-gray-100 text-gray-500 hover:text-green-500 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-gray-200"
                >
                  <span className="flex items-center text-sm">
                    <IoLogOutOutline
                      className="w-4 h-4 mr-3"
                      aria-hidden="true"
                    />
                    <span>{t("LogOut")}</span>
                  </span>
                </li>
              </ul>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default DashboardHeader;
