import React from "react";
import { AiOutlineFolderAdd, AiOutlineGift } from "react-icons/ai";
import { FiPackage, FiShoppingBag } from "react-icons/fi";
import { MdOutlineLocalOffer } from "react-icons/md";
import { RxDashboard } from "react-icons/rx";
import { VscNewFile } from "react-icons/vsc";
import { CiMoneyBill, CiSettings } from "react-icons/ci";
import { Link } from "react-router-dom";
import { BiMessageSquareDetail } from "react-icons/bi";
import { HiOutlineReceiptRefund } from "react-icons/hi";
import { useSelector } from "react-redux";

const DashboardSideBar = ({ active }) => {
  const { seller } = useSelector((state) => state.seller);

  return (
    <div className="w-full h-[100vh] bg-[#002D62] py-3 flex flex-col gap-1 shadow-sm overflow-y-auto sticky top-0 left-0 z-10">
      <div className="w-full flex flex-col mb-1 items-center py-1">
        <img
          src={`${seller.avatar}`}
          alt=""
          className="w-[30px] h-[30px] rounded-full object-cover mb-1"
        />
        <p className="hidden 800px:block text-[14px] text-white font-[500] mb-1">{`${seller?.name}`}</p>
        <p className="hidden 800px:block text-[11px] text-[#f5f5f5]">{`${seller?.email}`}</p>
      </div>

      {/* single item */}
      <div
        className={`w-full flex hover:bg-[#00000010] items-center px-4 py-2 ${
          active === 1 ? "bg-[#6CB4EE]" : "bg-transparent"
        }`}
      >
        <Link to="/dashboard" className="w-full flex items-center">
          <RxDashboard size={25} color="#fff" />
          <h5 className="hidden 800px:block pl-4 text-[14px] font-[500] text-[#fff]">
            Dashboard
          </h5>
        </Link>
      </div>

      <div
        className={`w-full flex hover:bg-[#00000010] items-center px-4 py-2 ${
          active === 2 ? "bg-[#6CB4EE]" : "bg-transparent"
        }`}
      >
        <Link to="/dashboard-orders" className="w-full flex items-center">
          <FiShoppingBag size={25} color="#fff" />
          <h5 className="hidden 800px:block pl-4 text-[14px] font-[500] text-[#fff]">
            All Orders
          </h5>
        </Link>
      </div>

      <div
        className={`w-full flex hover:bg-[#00000010] items-center px-4 py-2 ${
          active === 3 ? "bg-[#6CB4EE]" : "bg-transparent"
        }`}
      >
        <Link to="/dashboard-products" className="w-full flex items-center">
          <FiPackage size={25} color="#fff" />
          <h5 className="hidden 800px:block pl-4 text-[14px] font-[500] text-[#fff]">
            All Products
          </h5>
        </Link>
      </div>

      <div
        className={`w-full flex hover:bg-[#00000010] items-center px-4 py-2 ${
          active === 4 ? "bg-[#6CB4EE]" : "bg-transparent"
        }`}
      >
        <Link
          to="/dashboard-create-product"
          className="w-full flex items-center"
        >
          <AiOutlineFolderAdd size={25} color="#fff" />
          <h5 className="hidden 800px:block pl-4 text-[14px] font-[500] text-[#fff]">
            Create Product
          </h5>
        </Link>
      </div>

      <div
        className={`w-full flex hover:bg-[#00000010] items-center px-4 py-2 ${
          active === 5 ? "bg-[#6CB4EE]" : "bg-transparent"
        }`}
      >
        <Link to="/dashboard-events" className="w-full flex items-center">
          <MdOutlineLocalOffer size={25} color="#fff" />
          <h5 className="hidden 800px:block pl-4 text-[14px] font-[500] text-[#fff]">
            All Events
          </h5>
        </Link>
      </div>

      <div
        className={`w-full flex hover:bg-[#00000010] items-center px-4 py-2 ${
          active === 6 ? "bg-[#6CB4EE]" : "bg-transparent"
        }`}
      >
        <Link to="/dashboard-create-event" className="w-full flex items-center">
          <VscNewFile size={25} color="#fff" />
          <h5 className="hidden 800px:block pl-4 text-[14px] font-[500] text-[#fff]">
            Create Event
          </h5>
        </Link>
      </div>

      <div
        className={`w-full flex hover:bg-[#00000010] items-center px-4 py-2 ${
          active === 7 ? "bg-[#6CB4EE]" : "bg-transparent"
        }`}
      >
        <Link
          to="/dashboard-withdraw-money"
          className="w-full flex items-center"
        >
          <CiMoneyBill size={25} color="#fff" />
          <h5 className="hidden 800px:block pl-4 text-[14px] font-[500] text-[#fff]">
            Withdraw Money
          </h5>
        </Link>
      </div>

      <div
        className={`w-full flex hover:bg-[#00000010] items-center px-4 py-2 ${
          active === 8 ? "bg-[#6CB4EE]" : "bg-transparent"
        }`}
      >
        <Link to="/dashboard-messages" className="w-full flex items-center">
          <BiMessageSquareDetail size={25} color="#fff" />
          <h5 className="hidden 800px:block pl-4 text-[14px] font-[500] text-[#fff]">
            Shop Inbox
          </h5>
        </Link>
      </div>

      <div
        className={`w-full flex hover:bg-[#00000010] items-center px-4 py-2 ${
          active === 9 ? "bg-[#6CB4EE]" : "bg-transparent"
        }`}
      >
        <Link to="/dashboard-coupouns" className="w-full flex items-center">
          <AiOutlineGift size={25} color="#fff" />
          <h5 className="hidden 800px:block pl-4 text-[14px] font-[500] text-[#fff]">
            Discount Codes
          </h5>
        </Link>
      </div>

      <div
        className={`w-full flex hover:bg-[#00000010] items-center px-4 py-2 ${
          active === 10 ? "bg-[#6CB4EE]" : "bg-transparent"
        }`}
      >
        <Link to="/dashboard-refunds" className="w-full flex items-center">
          <HiOutlineReceiptRefund size={25} color="#fff" />
          <h5 className="hidden 800px:block pl-4 text-[14px] font-[500] text-[#fff]">
            Refunds
          </h5>
        </Link>
      </div>

      <div
        className={`w-full flex hover:bg-[#00000010] items-center px-4 py-2 ${
          active === 11 ? "bg-[#6CB4EE]" : "bg-transparent"
        }`}
      >
        <Link to="/settings" className="w-full flex items-center">
          <CiSettings size={25} color="#fff" />
          <h5 className="hidden 800px:block pl-4 text-[14px] font-[500] text-[#fff]">
            Settings
          </h5>
        </Link>
      </div>
    </div>
  );
};

export default DashboardSideBar;
