// reducers.js
const initialState = {
  buttonClicks: false,
};

export const buttonClickReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CAPTURE_BUTTON_CLICK":
      return {
        ...state,
        buttonClicks: !state.buttonClicks,
      };
    default:
      return state;
  }
};
