import React, { useState } from "react";
import { BiChevronRight } from "react-icons/bi";
import "./DeliveryinformationCard.css";
import { CiDeliveryTruck } from "react-icons/ci";
import { GoGift } from "react-icons/go";
import NumberFormat from "react-number-format";
import { RiRefund2Fill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addTocart } from "../../redux/actions/cart";

const DeliveryinformationCard = ({ productdata }) => {
  const { cart } = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.products);
  const [count, setCount] = useState(1);

  const addToCartHandler = (id) => {
    const isItemExists = cart && cart.find((i) => i._id === id);
    if (isItemExists) {
      toast.error("Item already in cart!");
    } else {
      if (productdata.stock < 1) {
        toast.error("Product stock limited!");
      } else {
        const cartData = { ...productdata, qty: count };
        dispatch(addTocart(cartData));
        toast.success("Item added to cart successfully!");
      }
    }
  };

  const totalReviewsLength =
    products &&
    products.reduce((acc, product) => acc + product.reviews?.length, 0);

  const totalRatings =
    products &&
    products.reduce(
      (acc, product) =>
        acc + product.reviews.reduce((sum, review) => sum + review.rating, 0),
      0
    );

  const avg = totalRatings / totalReviewsLength || 0;
  const averageRating = avg.toFixed(2);

  return (
    <div className="w-full flex flex-col gap-3 rounded-xl">
      <div className="bg-[white] w-[100] gap-3 rounded-lg flex flex-col py-4 px-2">
        <div className="seller_information flex align-center justify-between pb-2">
          <span className="text-[13px] font-[500]">DELIVERY & RETURN</span>
          <BiChevronRight size={20} color="#333" />
        </div>

        <div className="flex gap-2 w-full">
          <div className="deliver_return p-1 h-[40px] rounded flex items-center justify-center">
            <CiDeliveryTruck size={27} />
          </div>

          <div className="flex flex-col gap-y-1 w-full">
            <div className="flex align-center justify-between">
              <span className="font-[600] text-[13px]"> Door Delivery </span>
              <span className="font-[400] text-[12px] text-[blue] cursor-pointer">
                {" "}
                Details{" "}
              </span>
            </div>

            <div className="flex align-center gap-x-1 text-[13px]">
              <span>Delivery Fee</span>
              <span className="font-[600]">
                <NumberFormat
                  value={4600}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"XOF "}
                />
              </span>
            </div>

            <div className="font-[300] text-[11px]">
              <span>
                {" "}
                Ready for delivery between 04 October & 05 October when you
                order within next 2hrs 52mins{" "}
              </span>
            </div>
          </div>
        </div>

        <div className="flex gap-2 w-full">
          <div className="deliver_return p-1 h-[40px] rounded flex items-center justify-center">
            <GoGift size={27} />
          </div>

          <div className="flex flex-col gap-y-1oo w-full">
            <div className="flex align-center justify-between">
              <span className="font-[600] text-[13px]"> Door Delivery </span>
              <span className="font-[400] text-[12px] text-[blue] cursor-pointer">
                {" "}
                Details{" "}
              </span>
            </div>

            <div className="flex align-center gap-x-1 text-[13px]">
              <span>Pickup Station</span>
              <span className="font-[600]">
                <NumberFormat
                  value={2730}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"XOF "}
                />
              </span>
            </div>

            <div className="font-[300] text-[11px]">
              <span>
                {" "}
                Arriving at pickup station between 04 October & 05 October when
                you order within next 2hrs 52mins{" "}
              </span>
            </div>
          </div>
        </div>

        <div className="flex gap-2 w-full">
          <div className="deliver_return p-1 h-[40px] rounded flex items-center justify-center">
            <RiRefund2Fill size={27} />
          </div>
          <div className="flex flex-col gap-y-1oo w-full">
            <div className="flex align-center justify-between">
              <span className="font-[600] text-[13px]"> Return Policy </span>
              <span className="font-[400] text-[12px] text-[blue] cursor-pointer">
                {" "}
                Details{" "}
              </span>
            </div>

            <div className="font-[300] text-[11px]">
              <span>
                {" "}
                Free return within 15 days for Official Store items and 7 days
                for other eligible items.{" "}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[white] w-[100] gap-2 rounded-lg flex flex-col py-4 px-2">
        <div className="seller_information flex align-center justify-between pb-2">
          <span className="text-[13px] font-[500]">SELLER INFORMATION</span>
          <BiChevronRight size={20} color="#333" />
        </div>

        <div className="w-full flex flex-col gap-1">
          <div className="flex items-center justify-between gap-1 w-full">
            <img
              src={`${productdata?.shop?.avatar}`}
              className="w-[35px] h-[35px] rounded-full"
              alt=""
            />

            <div className="flex flex-col gap-1 ">
              <span className="text-[13px] font-[600]">
                {productdata.shop.name}
              </span>

              <h5 className="text-[12px] text-[14px]">
                ({averageRating}/5) Ratings
              </h5>
            </div>
          </div>

          <div className="flex flex-col gap-1 w-full">
            <span className="text-[13px] font-[600]">
              Joined on:{" "}
              <span className="font-[400]">
                {productdata.shop?.createdAt?.slice(0, 10)}
              </span>
            </span>

            <span className="text-[13px] font-[600]">
              Total Products:{" "}
              <span className="font-[400]">{products && products?.length}</span>
            </span>

            <span className="text-[13px] font-[600]">
              Total Reviews:{" "}
              <span className="font-[400]">{totalReviewsLength}</span>
            </span>
          </div>

          {/* <Link to={`/shop/preview/${productdata.shop._id}`}> */}
          <div className="bg-[orange] w-full flex align-center justify-center text-white text-[13px] py-2 rounded font-[600] cursor-pointer shadow-md text-[13px]">
            Visit Shop
          </div>
          {/* </Link> */}
        </div>
      </div>

      <div className="bg-[white] w-[100] gap-3 rounded-lg flex flex-col py-4 px-2">
        <Link to="/checkout" className="w-full">
          <div className="bg-[red] w-[100%] flex align-center justify-center text-white text-[13px] py-3 rounded-3xl font-[700] cursor-pointer shadow-md">
            Buy Now
          </div>
        </Link>

        <button
          className="bg-[orange] w-[100%] flex align-center justify-center text-white text-[13px] py-3 rounded-3xl font-[700] cursor-pointer shadow-md"
          onClick={() => addToCartHandler(productdata._id)}
        >
          Add to Cart
        </button>
      </div>
    </div>
  );
};

export default DeliveryinformationCard;
