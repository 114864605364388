// import { Button } from "@material-ui/core";
import Button from "@mui/material/Button";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { AiFillCloseCircle, AiOutlineDelete } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Layout/Loader";
import { BiChevronDown } from "react-icons/bi";
import {
  getCoupons,
  createCoupon,
  deleteCoupon,
} from "../../redux/actions/coupouns";

const AllCoupons = () => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [minAmount, setMinAmout] = useState(null);
  const [maxAmount, setMaxAmount] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [value, setValue] = useState(null);
  const { seller } = useSelector((state) => state.seller);
  const { products } = useSelector((state) => state.products);
  const [selectedOption, setSelectedOption] = useState(
    "Choose a selected product"
  );
  const [clicked, setClicked] = useState(false);
  const dispatch = useDispatch();
  const { coupons } = useSelector((state) => state.coupon);

  useEffect(() => {
    dispatch(getCoupons(seller._id));
  }, [dispatch, seller._id]);

  const handleDelete = (id) => {
    dispatch(deleteCoupon(id));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const couponData = {
      name,
      minAmount,
      maxAmount,
      selectedProducts,
      value,
      shopId: seller._id,
    };
    dispatch(createCoupon(couponData));
  };

  const columns = [
    { field: "id", headerName: "Id", minWidth: 150, flex: 0.7 },
    {
      field: "name",
      headerName: "Coupon Code",
      minWidth: 180,
      flex: 1.4,
    },
    {
      field: "price",
      headerName: "Value",
      minWidth: 100,
      flex: 0.6,
    },
    {
      field: "Delete",
      flex: 0.8,
      minWidth: 120,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Button onClick={() => handleDelete(params.id)}>
              <AiOutlineDelete size={20} />
            </Button>
          </>
        );
      },
    },
  ];

  const row = [];

  coupons &&
    coupons.forEach((item) => {
      row.push({
        id: item._id,
        name: item.name,
        price: item.value + " %",
        sold: 10,
      });
    });

  return (
    <div className="w-[90%] 800px:w-[90%] shadow mx-8 my-10 p-3 py-7 bg-white overflow-y-auto flex flex-col gap-4">
      <div className="w-full mb-3 flex justify-center items-center relative">
        <h5 className="text-[20px] font-[600]  text-center">
          Available Coupons
        </h5>

        <div
          className={`bg-[blue] p-2 border !w-max text-[14px] absolute right-0 text-white cursor-pointer !rounded-[5px]`}
          onClick={() => setOpen(true)}
        >
          Create Coupon Code
        </div>
      </div>
      <DataGrid
        rows={row}
        columns={columns}
        pageSize={10}
        disableSelectionOnClick
        autoHeight
      />
      {open && (
        <div className="fixed top-0 left-0 w-full h-screen bg-[#00000062] z-[200] flex items-center justify-center">
          <div className="w-[90%] 800px:w-[40%] min-h-[80vh] bg-white rounded-md shadow p-4 relative">
            <div className="w-full flex justify-center items-center relative">
              <AiFillCloseCircle
                id="close-icon"
                className="absolute"
                onClick={() => setOpen(false)}
                size={30}
              />

              <h5 className="text-[25px] font-[600]  text-center">
                Create Coupon Code
              </h5>
            </div>

            {/* create coupoun code */}
            <form onSubmit={handleSubmit} className="flex flex-col gap-3">
              <br />
              <div>
                <label className="text-[14px]">
                  Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="name"
                  required
                  value={name}
                  className="mt-2 appearance-none block w-full p-2 px-3 border border-gray-300 rounded-[3px] placeholder-[#4444] focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter your coupon code name..."
                />
              </div>

              <div>
                <label className="text-[14px]">
                  Discount Percentenge <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="value"
                  value={value}
                  required
                  className="mt-2 appearance-none block w-full p-2 px-3 border border-gray-300 rounded-[3px] placeholder-[#4444] focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  onChange={(e) => setValue(e.target.value)}
                  placeholder="Enter your coupon code value..."
                />
              </div>

              <div>
                <label className="text-[14px]">Min Amount</label>
                <input
                  type="number"
                  name="value"
                  value={minAmount}
                  className="mt-2 appearance-none block w-full p-2 px-3 border border-gray-300 rounded-[3px] placeholder-[#4444] focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  onChange={(e) => setMinAmout(e.target.value)}
                  placeholder="Enter your coupon code min amount..."
                />
              </div>

              <div>
                <label className="text-[14px]">Max Amount</label>
                <input
                  type="number"
                  name="value"
                  value={maxAmount}
                  className="mt-2 appearance-none block w-full p-2 px-3 border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  onChange={(e) => setMaxAmount(e.target.value)}
                  placeholder="Enter your coupon code max amount..."
                />
              </div>

              <div>
                <label className="text-[14px]">Selected Product</label>

                <div
                  className="w-full flex text-[14px] p-2 mt-2 relative border rounded-[5px] h-[35px] overflow-hidden"
                  value={selectedProducts}
                  onChange={(e) => setSelectedProducts(e.target.value)}
                  onClick={() => setClicked(true)}
                >
                  <div
                    className="w-full text-[14px]"
                    value="Choose your selected products"
                  >
                    {selectedOption}
                  </div>

                  <BiChevronDown
                    size={25}
                    color="#333"
                    className="cursor-pointer absolute top-[25%] right-[10px]"
                  />
                </div>

                {clicked && (
                  <div
                    onMouseLeave={() => setClicked(false)}
                    className="bg-[white] w-auto border max-h-[150px] overflow-auto absolute flex flex-col"
                  >
                    {products &&
                      products?.map((i) => (
                        <div
                          onClick={() => {
                            setSelectedOption(i.name);
                            setClicked(false);
                          }}
                          className="w-[400px] p-2 hover:bg-[#00000010] text-[12px] whitespace-nowrap cursor-pointer text-ellipsis overflow-hidden 800px:w-[500px]"
                          value={i.name}
                          key={i.name}
                        >
                          {i.name}
                        </div>
                      ))}
                  </div>
                )}
              </div>

              <div className="my-3">
                <input
                  type="submit"
                  value="CREATE"
                  className="bg-[blue] text-[white] text-[15px] font-[500] appearance-none block w-full p-3 border border-gray-300 rounded-[5px] shadow placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 cursor-pointer"
                />
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllCoupons;
