import React from "react";
import { MdBorderClear } from "react-icons/md";
import { Link } from "react-router-dom";
import styles from "../../styles/styles";
import { useSelector } from "react-redux";

const Adminboxeslink = ({ boxlabel, linklabel, textnumber, bgcolor }) => {
  return (
    <div
      className={`max-w-[500px] min-w-[170px] flex-grow 800px:w-[30%] min-h-[20vh] h-[160px] ${bgcolor} shadow rounded px-2 py-5`}
    >
      <div className="flex items-center">
        <MdBorderClear size={30} className="mr-2" fill="#00000085" />
        <h3 className={`text-[16px] font-[600] leading-5 text-[#00000085]`}>
          {boxlabel}
        </h3>
      </div>
      <h5 className="pt-2 pl-[36px] text-[22px] font-[800]">{textnumber}</h5>
      <Link to="/admin-sellers">
        <h5 className="pt-4 text-[13px] pl-2 text-[#077f9c] hover:underline">
          {linklabel}
        </h5>
      </Link>
    </div>
  );
};

export default Adminboxeslink;
