import React from "react";
import AdminHeader from "../components/Layout/AdminHeader";
import AdminSideBar from "../components/Admin/Layout/AdminSideBar";
import AllUsers from "../components/Admin/AllUsers";
import Loader from "../components/Loading/Loading";
import { useSelector } from "react-redux";

const AdminDashboardUsers = () => {
  const { isLoading } = useSelector((state) => state.user);

  return (
    <div className="w-full overflow-x-auto relative">
      <AdminHeader />

      {isLoading && (
        <Loader
          type="spin"
          color="white"
          text="Creating Category"
          height="100%"
          width="100%"
        />
      )}
      <div className="w-full flex">
        <div className="flex items-start justify-between w-full">
          <div className="w-[50px] 800px:w-[300px] ">
            <AdminSideBar active={4} />
          </div>
          <AllUsers />
        </div>
      </div>
    </div>
  );
};

export default AdminDashboardUsers;
