import React from "react";
import { AiOutlineMoneyCollect } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import styles from "../../styles/styles";
import NumberFormat from "react-number-format";

const Adminboxes = ({ boxlabel }) => {
  const { adminOrders } = useSelector((state) => state.order);

  const adminEarning =
    adminOrders &&
    adminOrders.reduce((acc, item) => acc + item.totalPrice * 0.1, 0);

  const adminBalance = adminEarning?.toFixed(2);

  return (
    <div className="max-w-[500px]  flex-grow min-w-[170px] 800px:w-[30%] h-[160px] bg-[#94ffb6] shadow rounded px-2 py-5">
      <div className="flex items-center">
        <AiOutlineMoneyCollect size={30} className="mr-2" fill="#00000085" />
        <h3 className={`text-[16px] font-[600] leading-5 text-[#00000085]`}>
          {boxlabel}
        </h3>
      </div>
      <h5 className="pt-2 pl-[36px] text-[22px] font-[800]">
        <NumberFormat
          value={adminBalance}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"XOF "}
        />
      </h5>
    </div>
  );
};

export default Adminboxes;
