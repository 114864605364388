import React, { useState, useEffect } from "react";
import { IoBagHandleOutline } from "react-icons/io5";
import { HiOutlineMinus, HiPlus } from "react-icons/hi";
import styles from "../styles/styles";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addTocart, removeFromCart } from "../redux/actions/cart";
import { toast } from "react-toastify";
import Header from "../components/Layout/Header/Header";
import { AiOutlineDelete } from "react-icons/ai";
import NumberFormat from "react-number-format";
import AllProduct from "../components/Products/Product";
import { useParams, useSearchParams } from "react-router-dom";
import Topselling from "./Topselling/Topselling";
import emptyCart from "../Assests/animations/cart-empty.json";
import Lottie from "lottie-react";
import Footer from "../components/Layout/Footer";
import { useLocation } from "react-router-dom";

const CartPage = () => {
  const location = useLocation();

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [location]);

  const { cart } = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  const style = {
    height: 200,
  };

  const removeFromCartHandler = (data) => {
    dispatch(removeFromCart(data));
  };

  const totalPrice = cart.reduce(
    (acc, item) => acc + item.qty * item.discountPrice,
    0
  );

  const quantityChangeHandler = (data) => {
    dispatch(addTocart(data));
  };

  return (
    <div className="w-full relative items-center">
      <Header />

      <div className="w-full my-[20px] flex flex-col gap-[20px] px-[60px]">
        <div className="w-full flex gap-[20px]">
          {cart && cart?.length === 0 ? (
            <div className="w-full  maxh-screen flex flex-col items-center gap-1 justify-center">
              <Lottie animationData={emptyCart} style={style} />
              <h5 className="text-[14px] font-[500]">Cart Items is empty!</h5>
              <h5 className="text-[12px] font-[300]">
                Browse our categories and discover our best deals!
              </h5>

              <Link
                to="/"
                className="text-[13px] font-[500] p-2 px-4 rounded bg-[#3E8EDE] text-white mt-1"
              >
                Resume Shopping
              </Link>
            </div>
          ) : (
            <div className="flex relative w-full flex justify-between gap-5">
              <div className="bg-[white] rounded-md w-full overflow-hidden">
                {/* Item length */}
                <div className={"p-4 py-3 flex align-center"}>
                  <IoBagHandleOutline size={25} />
                  <h5 className="pl-2 text-[18px] font-[500]">
                    {cart && cart?.length} items in Cart
                  </h5>
                </div>

                <div className="border-t w-full my-1" />
                {/* cart Single Items */}

                <div className="w-full gap-1 flex flex-col">
                  {cart &&
                    cart?.map((i, index) => (
                      <CartSingle
                        key={index}
                        data={i}
                        quantityChangeHandler={quantityChangeHandler}
                        removeFromCartHandler={removeFromCartHandler}
                      />
                    ))}
                </div>
              </div>

              <div className="sticky top-[90px] w-[30%] flex flex-col h-fit min-w-[250px] bg-[white] rounded-md">
                {/* checkout buttons */}
                <div className="p-2 w-fill flex text-[15px] font-[700] align-center justify-start text-[#333]">
                  CART SUMMARY
                </div>

                <div className="border-t w-full my-1" />

                <div className="p-2 py-1 w-fill flex text-[14px] font-[600] align-center justify-between text-[#333]">
                  <span>Subtotal</span>
                  <NumberFormat
                    value={totalPrice}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"XOF "}
                  />
                </div>

                <div className="border-t w-full my-1" />

                <Link to="/checkout" className="w-full p-2 py-1">
                  <div
                    className={`h-[45px] flex items-center justify-center w-[100%] bg-[#e44343] rounded-[5px]`}
                  >
                    <h1 className="text-[#fff] text-[14px] font-[700]">
                      CHECKOUT (
                      <NumberFormat
                        value={totalPrice}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"XOF "}
                      />
                      )
                    </h1>
                  </div>
                </Link>
              </div>
            </div>
          )}
        </div>

        <Topselling />
        <AllProduct title="You may also like" />
      </div>

      <Footer />
    </div>
  );
};

const CartSingle = ({ data, quantityChangeHandler, removeFromCartHandler }) => {
  const [value, setValue] = useState(data.qty);
  const totalPrice = data.discountPrice * value;

  const increment = (data) => {
    if (data.stock < value) {
      toast.error("Product stock limited!");
    } else {
      setValue(value + 1);
      const updateCartData = { ...data, qty: value + 1 };
      quantityChangeHandler(updateCartData);
    }
  };

  const decrement = (data) => {
    setValue(value === 1 ? 1 : value - 1);
    const updateCartData = { ...data, qty: value === 1 ? 1 : value - 1 };
    quantityChangeHandler(updateCartData);
  };

  return (
    <div className="border-b p-4 py-2 cursor-pointer">
      <div className="w-full flex flex-col items-center gap-0.5">
        <div className="w-full gap-[10px] py-2 flex">
          <img
            src={`${data?.images[0]?.url}`}
            alt=""
            className="w-[80px] h-min rounded-[5px]"
          />

          <div className="pl-[5px]">
            <h1>{data.name}</h1>
            <h4 className="font-[400] text-[15px] text-[#00000082]">
              ${data.discountPrice} * {value}
            </h4>
            <h4 className="font-[600] text-[17px] pt-[3px] text-[#d02222] font-Roboto">
              <NumberFormat
                value={totalPrice}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"XOF "}
              />
            </h4>
          </div>
        </div>

        <div className="w-full flex items-center justify-between">
          <div
            onClick={() => removeFromCartHandler(data)}
            className="flex rounded-md items-center p-1 cursor-pointer hover:shadow-sm hover:bg-[#e4434321]"
          >
            <AiOutlineDelete
              className="cursor-pointer"
              onClick={() => removeFromCartHandler(data)}
              size={23}
              color="#e44343"
            />

            <span className="pl-2 text-[13px] text-[#e44343] font-[600]">
              REMOVE
            </span>
          </div>

          <div className="flex gap-3">
            <div
              className={`bg-[#e44343] border border-[#e4434373] rounded-sm w-[25px] h-[25px] ${styles.noramlFlex} justify-center cursor-pointer`}
              onClick={() => increment(data)}
            >
              <HiPlus size={16} color="#fff" />
            </div>

            <span className="text-[13px] font-[400]">{data.qty}</span>

            <div
              className="bg-[#a7abb14f] rounded-sm w-[25px] h-[25px] flex items-center justify-center cursor-pointer"
              onClick={() => decrement(data)}
            >
              <HiOutlineMinus size={16} color="#7d879c" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartPage;
