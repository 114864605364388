import React, { useEffect, useState } from "react";
import styles from "../../styles/styles";
import { AiOutlineArrowRight, AiOutlineMoneyCollect } from "react-icons/ai";
import { MdBorderClear } from "react-icons/md";
import { Link } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrdersOfAdmin } from "../../redux/actions/order";
import Loader from "../Layout/Loader";
import { getAllSellers } from "../../redux/actions/sellers";
import Adminboxes from "./Adminboxes";
import Adminboxeslink from "./Adminboxeslink";
import NumberFormat from "react-number-format";

const AdminDashboardMain = () => {
  const dispatch = useDispatch();

  const { adminOrders } = useSelector((state) => state.order);
  const { sellers } = useSelector((state) => state.seller);

  useEffect(() => {
    dispatch(getAllOrdersOfAdmin());
    dispatch(getAllSellers());
  }, [dispatch]);

  const adminEarning =
    adminOrders &&
    adminOrders.reduce((acc, item) => acc + item.totalPrice * 0.1, 0);

  const adminBalance = adminEarning?.toFixed(2);

  const columns = [
    { field: "id", headerName: "Order ID", minWidth: 150, flex: 0.7 },

    {
      field: "status",
      headerName: "Status",
      minWidth: 130,
      flex: 0.7,
      cellClassName: (params) => {
        return params.row.status === "Delivered"
          ? "bg-[green] text-[white]"
          : params.row.status === "Processing"
          ? "bg-[red] text-[white]"
          : params.row.status === "Refund Success"
          ? "bg-[teal] text-[white]"
          : "bg-[orange] text-[white]";
      },
    },
    {
      field: "itemsQty",
      headerName: "Items Qty",
      type: "number",
      minWidth: 130,
      flex: 0.7,
    },

    {
      field: "total",
      headerName: "Total",
      type: "number",
      minWidth: 130,
      flex: 0.8,
    },
    {
      field: "createdAt",
      headerName: "Order Date",
      type: "number",
      minWidth: 130,
      flex: 0.8,
    },
  ];

  const row = [];
  adminOrders &&
    adminOrders.forEach((item) => {
      const formattedTotal = (
        <NumberFormat
          value={item?.totalPrice}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"XOF "}
          renderText={(formattedValue) => `XOF ${formattedValue}`} // Function to get the string value with prefix
        />
      );

      row.push({
        id: item._id,
        itemsQty: item?.cart?.reduce((acc, item) => acc + item.qty, 0),
        total: formattedTotal.props.renderText(
          formattedTotal.props.value.toLocaleString()
        ),
        status: item?.status,
        createdAt: item?.createdAt.slice(0, 10),
      });
    });

  return (
    <div className="relative w-[100%]">
      <div className="w-full p-4 flex flex-col gap-6">
        <div className="w-full flex flex-col gap-2">
          <h3 className="text-[18px] font-[600] text-bold">Overview</h3>

          <div className="w-full flex 800px:flex gap-3 items-around justify-center flex-wrap">
            <Adminboxes boxlabel="Total Earning" />

            <Adminboxeslink
              boxlabel="All Sellers"
              linklabel="View Sellers"
              textnumber={sellers && sellers?.length}
              bgcolor="bg-[#ffff]"
            />

            <Adminboxeslink
              boxlabel="All Orders"
              linklabel="View Orders"
              textnumber={adminOrders && adminOrders?.length}
              bgcolor="bg-[#94ffb6]"
            />
          </div>
        </div>

        <div className="w-full flex flex-col gap-2">
          <h3 className="text-[18px] font-[600] text-bold">Latest Orders</h3>

          <div className="w-full max-h-[50vh] overflow-y-auto bg-white rounded">
            <DataGrid
              rows={row}
              columns={columns}
              pageSize={4}
              disableSelectionOnClick
              autoHeight
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboardMain;
