import React from "react";
import AdminHeader from "../components/Layout/AdminHeader";
import AdminSideBar from "../components/Admin/Layout/AdminSideBar";
import AllEvents from "../components/Admin/AllEvents";
import Loader from "../components/Loading/Loading";
import { useSelector } from "react-redux";

const AdminDashboardEvents = () => {
  const { isLoading } = useSelector((state) => state.category);
  return (
    <div className="w-full overflow-x-auto relative">
      <AdminHeader />
      {isLoading && (
        <Loader
          type="spin"
          color="white"
          text="Creating Category"
          height="100%"
          width="100%"
        />
      )}

      <div className="w-full flex">
        <div className="flex items-start justify-between w-full">
          <div className="w-[50px] 800px:w-[300px] ">
            <AdminSideBar active={6} />
          </div>
          <AllEvents />
        </div>
      </div>
    </div>
  );
};

export default AdminDashboardEvents;
